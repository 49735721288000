import React, { Fragment, useState } from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import GoogleMapReact from "google-map-react"
import ContactForm from "../../components/forms/contact"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Obfuscate from "react-obfuscate"

const GeneralInquiry = () => {
    const [center] = useState({
        lat: 16,
        lng: 35
    })
    const [zoom] = useState(1)

    const mapOptions = {
        disableDefaultUI: true
    }

    const markers = [
        { lat: 55.6761, lng: 12.5683 }, // Copenhagen
        { lat: 28.7041, lng: 77.1025 }, // Delhi
        { lat: 25.2048, lng: 55.2708 }, // Dubai
        { lat: 19.076, lng: 72.8777 }, // Mumbai
        { lat: 1.3521, lng: 103.8198 } // Singapore
    ]

    const renderMarkers = (map, maps) => {
        const mapMarkers = markers.forEach(
            marker =>
                new maps.Marker({
                    position: marker,
                    map
                })
        )
        return mapMarkers
    }
    const data = useStaticQuery(graphql`
        query contactOfficesData {
            allOfficesJson {
                nodes {
                    id
                    office
                    legalEntity
                    street
                    postcode
                    city
                    country
                    emails
                }
            }
        }
    `)

    const locations = data.allOfficesJson.nodes

    return (
        <Layout>
            <Seo title="Contact" />
            <div className="contact-map">
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyDHbjASsFo0OsTChaxi9ymgqBibepTAn7E" }}
                    defaultCenter={center}
                    defaultZoom={zoom}
                    options={mapOptions}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                ></GoogleMapReact>
            </div>

            <div className="container-md">
                <div className="row contact-card mb-3">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h1 className="card-title">Contact us</h1>
                                <p className="card-text">
                                    Please use the below form for general enquries, and we will get back to you as soon as possible.
                                </p>
                                <div className="row mb-3">
                                    <div className="col-12 ">
                                        <ContactForm environment={4000} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xxl-3 g-3 mb-3">
                    {locations.map(location => {
                        return (
                            <Fragment key={location.id}>
                                <div className="col">
                                    <div className="card h-100 border-0 card-product ">
                                        <div className="card-body d-flex flex-column">
                                            <h2 className="text-light ">{location.office}</h2>
                                            <p className="text-light ">{location.legalEntity}</p>
                                            <div className="mb-2 text-light">
                                                <div className="d-flex">
                                                    <div>
                                                    <svg width="0.75em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="map-marker-alt" class="svg-inline--fa fa-map-marker-alt fa-w-12 fa-fw me-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M192 0C85.903 0 0 86.014 0 192c0 71.117 23.991 93.341 151.271 297.424 18.785 30.119 62.694 30.083 81.457 0C360.075 285.234 384 263.103 384 192 384 85.903 297.986 0 192 0zm0 464C64.576 259.686 48 246.788 48 192c0-79.529 64.471-144 144-144s144 64.471 144 144c0 54.553-15.166 65.425-144 272zm-80-272c0-44.183 35.817-80 80-80s80 35.817 80 80-35.817 80-80 80-80-35.817-80-80z"></path></svg>

                                                    </div>
                                                    <div className="flex-grow-1">{location.street}</div>
                                                </div>
                                                <div className="d-flex">
                                                    <div>
                                                    <svg  width="0.75em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="map-marker-alt" class="invisible me-3 svg-inline--fa fa-map-marker-alt fa-w-12 fa-fw me-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M192 0C85.903 0 0 86.014 0 192c0 71.117 23.991 93.341 151.271 297.424 18.785 30.119 62.694 30.083 81.457 0C360.075 285.234 384 263.103 384 192 384 85.903 297.986 0 192 0zm0 464C64.576 259.686 48 246.788 48 192c0-79.529 64.471-144 144-144s144 64.471 144 144c0 54.553-15.166 65.425-144 272zm-80-272c0-44.183 35.817-80 80-80s80 35.817 80 80-35.817 80-80 80-80-35.817-80-80z"></path></svg>

                                                    </div>
                                                    <div className="flex-grow-1">
                                                        {location.postcode} {location.city}
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <div>
                                                    <svg  width="0.75em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="map-marker-alt" class="invisible me-3 svg-inline--fa fa-map-marker-alt fa-w-12 fa-fw me-3" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M192 0C85.903 0 0 86.014 0 192c0 71.117 23.991 93.341 151.271 297.424 18.785 30.119 62.694 30.083 81.457 0C360.075 285.234 384 263.103 384 192 384 85.903 297.986 0 192 0zm0 464C64.576 259.686 48 246.788 48 192c0-79.529 64.471-144 144-144s144 64.471 144 144c0 54.553-15.166 65.425-144 272zm-80-272c0-44.183 35.817-80 80-80s80 35.817 80 80-35.817 80-80 80-80-35.817-80-80z"></path></svg>

                                                    </div>
                                                    <div className="flex-grow-1">{location.country}</div>
                                                </div>
                                            </div>

                                            {location.emails &&
                                                location.emails.map((email, idx) => {
                                                    return (
                                                        <div key={idx} className="mb-2 text-light">
                                                            <div className="d-flex">
                                                                <div>
                                                                <svg width="1em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" class="svg-inline--fa fa-envelope fa-w-16 fa-fw me-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path></svg>

                                                                </div>

                                                                <div className="flex-grow-1 ">
                                                                    <Obfuscate className="text-break text-light" email={email} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        )
                    })}
                </div>
            </div>
        </Layout>
    )
}

export default GeneralInquiry
